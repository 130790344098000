
import { ref } from 'vue'
import { mapActions, useStore } from 'vuex'
import router from "@/router"
import store from "@/store"
import axios from "axios"

export default {
	name: "Login",
	components: {},
	setup() {
		const store = useStore()
		const email = ref("")
		const password = ref("")
		const message = ref("")
		const loginMessage = ref("")
		const loginMessageClass = ref("")

		function saveResponseDataToSession(responseData) {
			if (!store.getters["session/getIsValidUser"](responseData)) {
				loginMessageClass.value = "is-danger"
				loginMessage.value = "Your user account is not configured for this service. Please contact support@lenslock.com."
				return
			}

			if (!store.getters["session/getIsValidOrganization"](responseData)) {
				loginMessageClass.value = "is-danger"
				loginMessage.value = "Your organization is not configured for this service. Please contact support@lenslock.com."
				return
			}

			store.dispatch("session/setSession", responseData)
			store.dispatch("profile/loadProfile", responseData)
			store.dispatch("form_data/getAllUserList", responseData)

			router.push("/home")
		}

		function clearEmail() {
			email.value = ""
		}

		function clearPassword() {
			password.value = ""
		}

		function onSubmit(event) {
			let emailParam = email.value.trim()
			let passwordParam = password.value.trim()

			if (emailParam.length == 0) {
				loginMessageClass.value = "is-danger"
				loginMessage.value = "Email must not be empty."
				return
			}

			if (passwordParam.length == 0) {
				loginMessageClass.value = "is-danger"
				loginMessage.value = "Password must not be empty."
				return
			}

			store.dispatch("dialog/openDialog")

			axios.post( "common/v1/session", {
				email: emailParam,
				password: passwordParam
			}).then(response => {
				store.dispatch("dialog/closeDialog")

				if (response.data) {
					saveResponseDataToSession(response.data)
					store.dispatch("session/setIsValid", 1)
					// TODO load form record list from API

					return
				}

				loginMessageClass.value = "is-danger"
				loginMessage.value = "An unknown error occurred."
			}).catch((error) => {
				console.log(error)
				loginMessageClass.value = "is-danger"
				loginMessage.value = "Email or password is incorrect. "

				if (event.getModifierState('CapsLock')) {
					loginMessage.value += "CAPSLOCK is enabled."
				}

				store.dispatch("dialog/closeDialog")
			})
		}

		// skip login if the session is valid
		if (store.getters["session/getIsValid"]) {
			router.push("/home")
		}

		return { email, password, loginMessage, loginMessageClass, clearEmail, clearPassword, onSubmit }
	},
	methods: {
		...mapActions("dialog", ["openDialog", "closeDialog",
			"setIcon", "setIconType", "setMessage",
			"setButtonLeftText", "setButtonLeftFunc",
			"setButtonRightText", "setButtonRightFunc",
			"setButtonXText", "setButtonXFunc"])
	},
	mounted() {
		this.setMessage("Connecting to server...")
		this.setIconType("has-text-primary")
		this.setIcon("fa-spinner fa-pulse")
		this.setButtonLeftText("CANCEL")
		this.setButtonLeftFunc(() => {
			this.closeDialog()
			store.dispatch("session/clearLocalStorage")
			router.push("/login")
		})
		this.setButtonRightText(null)
		this.setButtonRightFunc(null)
		this.setButtonXText(null)
		this.setButtonXFunc(null)
	}
}
